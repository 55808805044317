@mixin text($font-size,$line-height) {
  font-size: $font-size;
  line-height: $line-height;
}

@mixin sizes($width,$height){
  width: $width;
  height: $height;
}

@mixin size($size){
  width: $size;
  height: $size;
}

@mixin transition($transition){
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin rotate($angle){
  -webkit-transform: rotate($angle);
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  -o-transform: rotate($angle);
  transform: rotate($angle);
}

@mixin moveVertical($start,$stop){
  0% {transform: translateY($start);}
  0% {-webkit-transform: translateY($start);}
  0% {-moz-transform: translateY($start);}
  0% {-o-transform: translateY($start);}
  0% {-ms-transform: translateY($start);}
  100% {transform: translateY($stop);}
  100% {-webkit-transform: translateY($stop);}
  100% {-moz-transform: translateY($stop);}
  100% {-o-transform: translateY($stop);}
  100% {-ms-transform: translateY($stop);}
}

iframe#webpack-dev-server-client-overlay{display:none!important}
