@use    "sass:math";
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/_mixins';
//@import 'bootstrap-select/sass/bootstrap-select';
@import 'bootstrap-icons/font/bootstrap-icons.css';
@import 'functions';

$list-bg: ("green": $success, "blue": $info, "beige": $c-beige, "red": $danger, "purple": $c-purple, "orange": $c-orange, "pink": $c-pink, "darkblue": $c-blue, "yellow": $c-yellow,);

@each $list-bg, $color in $list-bg {
    .#{$list-bg}-outline {
        color: $color;
        border: 1px solid $color;
    }
}

$list-width: (24, 120, 180, 225, 230,);
@each $width in $list-width {
    .min-wp-#{$width} {
        min-width: #{$width} + "px";
    }
}

$list-width1: (13, 18, 19, 30, 40, 80, 90);
@each $width in $list-width1 {
    .w-#{$width} {
        width: #{$width} + "%";
    }
}

$list-width4: (120, 150, 250);
@each $width in $list-width4 {
  .max-w-#{$width} {
    max-width: #{$width} + "px";
  }
}

$list-width5: (90);
@each $width in $list-width5 {
  .max-wper-#{$width} {
    max-width: #{$width} + "%";
  }
}

$list-width2: (15, 32, 50, 60, 80, 100, 130, 250, 300);
@each $width in $list-width2 {
    .wp-#{$width} {
        width: #{$width} + "px";
    }
}

$list-height1: (1);
@each $height in $list-height1 {
    .hp-#{$height} {
        width: #{$height} + "px";
    }
}

$list-height2: (180, 280, 360);
@each $height in $list-height2 {
  .min-hp-#{$height} {
    min-height: #{$height} + "px";
  }
}

body,
html {
    height: 100%;
    overflow-x: hidden;
}

body{
    font-family: $font-family;
    font-size: $font-size;
    * {
        position: relative;
    }
    @include media-breakpoint-down(lg) {
        font-size: $font-size-sm;
    }
}

h1 {
    font-weight: $h1-font-weight;
}

.mb--1 {
    margin-bottom: -1rem;
}

.mt--1 {
    margin-top: -1rem;
}

.mt-01 {
    margin-top: 0.1rem;
}

.mx--row {
    hr {
        margin-left: -2rem;
        margin-right: -2rem;
        background: $c-black1;
        opacity: 1;
    }
}

.cursor-default {
    cursor: default;
}

.card-without-cards {
    .card {
        border: none;
        @extend .mt-1;
        .card-body {
            padding: 0;
        }
    }
}

.content-styles {
    ul {
        list-style: none;
        padding-left: 0;
        li {
            position: relative;
            padding-left: 2 * $font-size;
            margin-bottom: math.div(2 * $font-size, 3);
            &::before {
                content: "\2022";
                color: $primary;
                font-size: $font-size;
                font-weight: bold;
                display: block;
                position: absolute;
                top: 0;
                left: math.div($font-size, 2);
            }
        }
    }
    ol {
        li {
            margin-bottom: math.div(2 * $font-size, 3);
        }
    }
}

a {
    @include transition(color 0.3s 0s ease);
    &:hover {
        text-decoration: none;
    }
}

@include media-breakpoint-down(lg) {
    .w-lg-100 {
        width: 100% !important;
    }
}

@include media-breakpoint-down(xl) {
    .border-lg-bottom {
        border-bottom: 1px solid $border-color;
    }
}

/*icons start*/
.icon {
    display: inline-block;
    -webkit-mask-size: contain !important;
    mask-size: contain !important;
    -webkit-mask-repeat: no-repeat !important;
    mask-repeat: no-repeat !important;
    -webkit-mask-position: center center !important;
    mask-position: center center !important;
}

.icon-see-all {
    -webkit-mask-image: url(../assets/img/icon-see-all.svg);
    mask-image: url(../assets/img/icon-see-all.svg);
    background-color: $c-white;
}
.icon-cleaning {
  -webkit-mask-image: url(../assets/img/icon-cleaning.svg);
  mask-image: url(../assets/img/icon-cleaning.svg);
  background-color: $primary;
  @include sizes($font-size-sm, $font-size-sm * 1.25);
}
/*icons end*/

/*colors start*/
.text-orange {
    color: $c-orange !important;
}

.bg-darkgreen {
  background: $c-darkgreen;
  color: $c-white;
}

.bg-pink {
  background: $c-pink;
  color: $c-white;
}

.bg-grape {
  background: $c-grape;
  color: $c-white;
}

.bg-orange {
    background: $c-orange;
    color: $c-white;
}

.bg-green {
    background: $c-green;
    color: $c-white;
}

.bg-blue {
    background: $c-blue;
    color: $c-white;
}

.bg-purple {
    background: $c-purple;
    color: $c-white;
}

.bg-grey {
    color: $secondary;
    background-color: $c-grey1;
}
/*colors end*/

/*alerts and messages start*/
.alert-warning {
  p {
    a {
      color: #4d3a01;
    }
  }
}
.alert-danger {
    background: $danger-bg;
    color: $danger;
  p {
    a {
      color: darken($danger,3%);
    }
  }
    table {
        thead {
            th {
                border-bottom: 1px solid $danger;
            }
        }
        td,
        th {
            padding: 0.5*$table-cell-padding-y $table-cell-padding-x;
        }
    }
    a {
        color: $black;
    }
}

.alert-sm {
    padding: $alert-padding-sm-y $alert-padding-sm-x;
}

.alert-cmp {
  .alert-cmp-btn {
    .btn-link {
      color: $c-black;
    }
  }
  &.alert-success {
    i:before {
      content: "\f26a";
    }
  }
  &.left {
    @extend .flex-row;
    i {
      @extend .me-2;
    }
    .alert-cmp-btn {
      @extend .ms-auto;
      .btn-link {
        @extend .ms-2;
      }
    }

  }
  &.center {
    @extend .flex-column;
    text-align: center;
    i {
      @extend .mt-1;
      margin-bottom: 1rem;
      font-size: 1.2*$font-size-lg;
    }
    .alert-cmp-btn {
      .btn-link {
        @extend .mt-2;
      }
    }
  }
  .btn-link {
    padding: 0;
    &:hover {
      text-decoration: none;
    }
  }
}

.d-flex + .was-validated {
    margin-top: -1.25rem;
}

.error-validated {
    .form-control {
        @extend .is-invalid;
        & + .invalid-feedback {
            display: none;
        }
    }
}

.invalid-message {
    color: $danger;
    font-size: $invalid-message-font-size;
    ul {
        padding-left: $invalid-message-pad-x;
        margin-bottom: 0;
    }
}
/*alerts and messages end*/

/*part of forms start*/
.input-or {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: 0;
    width: auto;
    margin-left: $input-or-marg-left;
    margin-top: $input-or-marg-t;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
        margin-top: $input-or-marg-t-lg;
    }
}
.form-group {
    margin-bottom: $form-group-marg-b;
}
.form-group-currency {
    input {
        @extend .form-control;
    }
}

.btn-outline-secondary {
  &:hover {
    background: $c-grey1 !important;
    color: $c-black !important;
  }
}

.nav.btn-group {
    &.flex-lg-row {
        @include media-breakpoint-down(lg) {
            > .btn:first-child {
                border-radius: $btn-border-radius $btn-border-radius 0 0;
            }
            > .btn:last-child {
                border-radius: 0 0 $btn-border-radius $btn-border-radius;
            }
            > .btn:not(:first-child) {
                margin-left: 0;
            }
        }
    }
}

.radio-btns {
  gap: 12px;
}
.radio-btn {
  position: relative;
  .btn {
    border-radius: 32px;
    color: $dark !important;
    background-color: $gray-200 !important;
    border-color: $gray-400 !important;
    border-width: 2px !important;
    @extend .py-1;
    @extend .px-3;
    &:hover {
      border-color: $primary !important;
    }
    & + i {
      display: none;
    }
    @include media-breakpoint-down(md) {
      padding: 0.25rem 0.75rem !important;
    }
  }
  .btn-check:checked + .btn {
    border-color: $primary !important;
    @include media-breakpoint-up(sm) {
      padding-left: 30px !important;
      & + i {
        display: block;
        position: absolute;
        left: 8px;
        top: 4px;
        color: $primary;
        font-size: $font-size-lg;
      }
    }
  }
}

.transfer-status {
  border-radius: 24px;
  line-height: 1;
  background-color: $gray-200;
  border:  1px solid $gray-400;
  padding: 0.2rem 0.6rem;
}

.transfer-close {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 1.5*$font-size-lg;
  color: $dark;
  text-decoration: none;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.choose-dialog-with-link-edit {
    .btn.btn-primary {
        background: none;
        border: none;
        @extend .btn-link;
        @extend .py-0;
        @extend .px-1;
        font-size: 0;
        .bi-pencil.d-none {
            font-size: $font-size;
            display: block !important;
        }
        &:hover {
            color: $secondary;
        }
    }
}

a.disabled {
    pointer-events: none;
    cursor: default;

    i {
        color: $header-nav-separator-color;
    }
}

.react-datepicker__tab-loop {
/*    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
        margin-left: -11px;
    }*/
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-bottom-color: $c-grey;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
        border-bottom-color: $input-border-color;
    }
}
.react-datepicker {
    font-family: $font-family !important;
    border-color: $input-border-color !important;
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 1rem;
    }
    .react-datepicker__navigation-icon::before {
        top: $font-size-sm;
    }
    .react-datepicker__header {
        background-color: $c-grey;
        border-bottom-color: $input-border-color;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: $primary;
        color: $white;
    }
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover,
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
        background-color: tint-color($primary, 15%);
    }
}
.react-datepicker__input-container {
    input {
        @extend .form-control;
    }
}

.switch-header {
    @extend .d-flex;
    @extend .align-items-center;
    .form-check-label.h5 {
        margin-right: $form-switch-padding-start * 0.35;
    }
    .form-switch {
        margin-bottom: 0;
        padding-left: $form-switch-padding-start * 1.2;
        .form-check-input {
            margin-left: - $form-switch-padding-start * 1.2;
        }
        label {
            @extend .h5;
        }
    }
}

.form-group-datapicker {
  .form-label {
    display: block;
  }
}

.license-select-txt {
  max-width: 816px;
  margin: 0 auto;
}

.license-select-l {
  max-height: 330px;
  overflow-y: auto;
  li {
    padding: $input-padding-y $input-padding-x;
    border: 1px solid $border-color;
    border-radius: var(--bs-border-radius);
    background: $white;
    & + li {
      @extend .mt-2;
    }
  }
}

.switch-year {
    @extend .d-flex;
    @extend .align-items-center;
    .form-switch {
        margin-bottom: 0;
    }
}

.password-with-icon {
  position: relative;
  .password-with-icon-i {
    position: absolute;
    right: 35px;
    top: 36px;
    &:not(:hover){
      opacity: 0.5;
    }
    &:hover {
      color: $primary;
    }
  }
}

.input-year {
  .mb-3,
    .form-group {
        margin-bottom: 0 !important;
    }
    .form-label {
        display: none;
    }
}

.form-check {
    .form-group {
        margin-bottom: 0;
    }
}

.form-check-input-with-year {
    margin-top: 0.7em;
}

.form-group-line {
    .mb-3 {
        @extend .d-flex;
        @extend .flex-row;
        @extend .align-items-center;
        @extend .justify-content-center;
        .form-label {
            margin-bottom: 0;
            margin-right: 2 * $form-label-margin-bottom;
        }
    }
}

.form-group-sm {
    .form-control {
        @extend .form-control-sm;
    }
}

.form-group-without-m {
    .mb-3,
    .form-group {
        margin-bottom: 0 !important;
    }
}

.form-group-without-label {
    .form-label {
        display: none;
    }
}

.form-label-with-tooltip {
    @extend .d-flex;
    @extend .flex-row;
    @extend .align-items-center;
    margin-bottom: $form-label-margin-bottom;
    .form-label {
        margin-bottom: 0;
    }
    & + .form-group {
        @extend .form-group-without-label;
    }
}

.form-check-label.disabled {
    @extend .opacity-50;
}

.disabled-form {
    position: relative;
    .form-select {
        color: lighten($secondary,20%);
        background-blend-mode: exclusion;
    }
    .select-search__value::after {
        @extend .opacity-25;
    }
    .tooltip-wr,
    .form-label,
    .form-check-label,
    p {
        @extend .opacity-50;
    }
    .form-control,
    .form-select,
    .form-check-input,
    .form-check-input:checked {
        border-color: $input-border-color;
        background-color: $input-disabled-bg;
        ~ .form-check-label {
            @extend .opacity-50;
        }
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        @extend .opacity-50;
    }
}

.table-responsive .dropdown,
.table-responsive .btn-group,
.table-responsive .btn-group-vertical {
  position: static;
}

.btn.dropdown-toggle {
  & + .dropdown-menu.show {
      min-width: 100%;
  }
}

.dropstart .dropdown-toggle {
  &::before {
    display: none;
  }
  & + .dropdown-menu.show {
    margin-top: 0 !important;
  }
}

.dropdown-dots {
  text-align: center;
  &:not(.btn-sm) {
    padding: 5px;
    width: 35px;
    height: 35px;
  }
  &.btn-sm {
    padding: 0 2px;
    width: 21px;
    height: 21px;
    i {
      margin-top: -5px;
    }
  }
  &::after {
    display: none;
  }
  &:not(.show){
    background-color: $c-grey;
    border-color: $c-grey1;
    &.show,
    &:hover {
      background-color: $primary;
      color: $c-white;
      border-color: $primary;
    }
  }
}

.admin-calendar-li {
  border: 1px solid $form-select-border-color;
  border-radius: 8px;
  background: $c-white;
  margin-bottom: 0.8rem;
  .header {
    background: #f8f9fb;
    min-width: 230px;
    max-width: 230px;
    padding: 2.5em 2em;
    h5 {
      font-weight: bold;
      @extend .mb-0;
    }
  }
  .body {
    flex-grow: 1;
    label {
      color: $c-black;
      opacity: 0.5;
      @extend .me-2;
    }
  }
  .body-block {
    padding: 1em 2em 1.5em 2em;
    &:nth-child(1),
    &:nth-child(2) {
      width: 27%;
    }
    &:last-child {
      width: 46%;
    }
    h5 {
      font-size: round($font-size * 1.1);
    }
    .invalid-feedback {
      margin-top: 0.5rem;
    }
    @media (min-width: 1600px){
      .wp-50 {
        width: 60px !important;
      }
    }
  }
  &.admin-calendar-li-active {
    background: #FBFCFC;
    .header {
      background: #E0EAEC;
      color: $primary;
    }
  }
  @include media-breakpoint-up(xxl) {
    .header {
      border-radius: 8px 0 0 8px;
      border-right: 1px solid $form-select-border-color;
    }
  }
  @media (max-width: 1650px){
    .header {
      min-width: 190px;
      max-width: 190px;
    }
    .body-block {
      padding: 1em 2em;
      &:nth-child(1),
      &:nth-child(2) {
        width: 30%;
      }
      &:last-child {
        width: 40%;
      }
    }
  }
  @media (min-width: 1400px) and (max-width:  1520px){
    .header {
      min-width: 230px;
      max-width: 230px;
    }
    .body-block {
      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
      }
      &:last-child {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-down(xxl) {
    .header {
      max-width: 100%;
      padding: 1em;
      border-radius: 8px 8px 0 0;
      border-bottom: 1px solid $form-select-border-color;
      h5 {
        margin: 0 auto;
      }
    }
    .body-block h5,
    h5 {
      font-size: $font-size;
    }
    .body-block {
      padding: 1em;
      width: 100%;
    }
  }
  @media (max-width: 1210px){
    .body-block {
      &:nth-child(1),
      &:nth-child(2) {
        width: 50%;
      }
      &:last-child {
        width: 100%;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .body-block {
      width: 100% !important;
      h5 {
        font-size: $font-size-sm;
        font-weight: bold;
      }
    }
  }
}
.admin-calendar {
    .form-control {
      min-height: calc(1.5em + 0.3rem + 2px);
      padding: 0.15rem 0.35rem;
      &[disabled]{
        background-color: $c-white;
        color: $c-black;
        opacity: 0.6;
      }
    }
    .form-group-sm + .form-group-sm {
      position: relative;
      @extend .ms-2;
      @extend .ps-1;
    }
}
/*part of forms end*/

/*steps start*/
.steps-wr {
    @extend .text-white;
    border-radius: calc($border-radius-lg + 0.1rem);
}
.steps-li {
    @extend .border-end;
    @extend .border-lg-bottom;
    @extend .d-flex;
    @extend .bg-primary;
    @include media-breakpoint-up(xl) {
        &:first-child {
            border-radius: $border-radius-lg 0 0 $border-radius-lg;
        }
        &:last-child {
            border-radius: 0 $border-radius-lg $border-radius-lg 0;
        }
    }
    @include media-breakpoint-down(xl) {
        &:first-child {
            border-radius: $border-radius-lg $border-radius-lg 0 0;
        }
        &:last-child {
            border-radius: 0 0 $border-radius-lg $border-radius-lg;
        }
    }
    strong {
        @extend .d-inline;
        @extend .bg-white;
        @extend .text-primary;
        @extend .rounded-circle;
        @extend .min-wp-24;
        @extend .me-3;
        @extend .text-center;
    }
    &.active {
        @extend .bg-secondary;
        strong {
            @extend .text-secondary;
        }
    }
}
.steps-li-in {
    @extend .py-2;
    @extend .px-3;
    @extend .px-lg-4;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-between;
    @include media-breakpoint-up(xl) {
        min-height: $steps-li-h;
    }
}
/*steps end*/

/*paginator start*/
.page-item.active .page-link {
    z-index: 1;
}
/*paginator end*/

/*header start*/
header {
    padding: $header-pad-y $header-pad-x;
    .navbar {
        padding: 0;
    }
    .navbar-brand {
        padding: 0;
    }
    .nav-link {
        font-size: $header-nav-font-size;
        color: $header-nav-link-color;
        padding-left: 0.7*$spacer;
        padding-right: 0.7*$spacer;
        &:hover {
            color: $header-nav-link-color-h;
        }
    }
    .navbar-toggler {
        border-left: solid 1px $header-nav-separator-color;
        &:focus {
            box-shadow: none;
        }
        &:not(.collapsed){
            .navbar-toggler-icon {
                @include rotate(180deg);
            }
        }
    }
    .navbar-toggler-icon {
        width: $navbar-toggler-icon-width;
        background-image: url("../assets/img/icon-arrow-down.svg");
    }
    .navbar-account {
        font-size: $h1-font-size;
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-menu {
            .dropdown-item {
                @include transition(color 0.15s ease);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .navbar-brand {
        img {
            height: $header-brand-height;
            max-width: 100%;
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0 - $header-pad-y;
            bottom: 0 - $header-pad-y;
            border-right: solid 1px $header-nav-separator-color;
        }
    }
    .nav {
        li {
            margin: 0 $header-pad-y;
        }
    }
}

@include media-breakpoint-down(xl) {
    header {
        .nav-link {
            padding-left: 0.5*$spacer;
            padding-right: 0.5*$spacer;
        }
    }
}

.navbar-collapse {
  .btn-outline-secondary {
    border-color: $header-nav-separator-color;
    &.show {
      background: none !important;
      color: $dark;
    }
  }
}

@include media-breakpoint-down(lg) {
    header {
        padding: 0;
        .container-fluid {
            padding: 0;
        }
        .nav-link {
            padding: 1.5*$header-pad-y 0;
        }
    }
    .navbar-collapse {
        display: flex;
        flex-direction: column;
        order: 0;
        border-bottom: solid 1px $header-nav-separator-color;
        .nav {
            flex-direction: column;
            order: 1;
            padding: 0 $header-pad-x;
            margin-top: $header-pad-x;
            li {
                border-top: solid 1px $header-nav-separator-color;
            }
        }
        form {
            order: 0;
            background-color: $c-grey;
            padding: 1.5*$header-pad-y $header-pad-x;
        }

    }
    .navbar-brand {
        order: 1;
        margin: $header-pad-y $header-pad-x;
        img {
            height: $header-brand-height-md;
        }
    }
    .navbar-toggler {
        order: 2;
    }
}

@media (min-width: 992px) and (max-width: 1352.98px){
    .navbar-expand-long {
        .navbar-collapse {
            margin-left: -0.8rem;
            .form-group-account {
                width: $form-group-account-w-lg;
            }
            .nav li {
                margin: 0 0.25rem;
                .nav-link {
                    font-size: 1.1rem;
                }
            }
            .form-search.collapsed {
                width: 0.8 * $input-search-width;
            }
            .form-search:not(.collapsed){
                width: calc(100% - $form-search-w-lg - $form-search-w-m);
                right: $form-search-w-lg;
            }
        }
        .navbar-brand {
            padding-right: 1.5rem !important;
            img {
                height: $header-brand-height-md;
            }
        }
    }
}
/*header end*/

/*search start*/
.form-search {
    @include transition($input-search-transition);
    .collapsing.collapse-horizontal {
        height: 0;
        @include transition(width 0s ease);
    }
}
.input-search {
    .form-control {
        padding-left: 2.2*$spacer;
        border-radius: $input-search-border-radius;
    }
    .bi-search {
        z-index: 1;
        position: absolute;
        left: math.div(3 * $spacer, 4);
        top: math.div($spacer, 2);
    }
    .btn-link {
        position: absolute;
        right: 0;
        top: math.div(3 * $btn-padding-y, 4);
        font-weight: bold;
        color: $input-search-c-link;
        text-decoration: none;
        padding: math.div($btn-padding-y, 4) 2*$btn-padding-x;
        border-left: solid 1px $header-nav-separator-color;
        border-radius: 0;
        &:focus {
            box-shadow: none;
        }
    }
}
@include media-breakpoint-up(xl) {
    .search-app-letter {
        min-width: $search-app-letter-width;
    }
}
@include media-breakpoint-down(xl) {
    .search-app-letter {
        width: 100%;
    }
}
@include media-breakpoint-up(lg) {
    .form-search {
        margin-right: math.div($input-search-marg-e,2);
        background: $c-white;
        &.collapsed {
            width: $input-search-width;
        }
        &:not(.collapsed) {
            $w-temp: $form-search-w-m + math.div($input-search-marg-e,2);
            width: calc(100% - $w-temp);
            position: absolute;
            right: $form-search-w-m;
        }
        &.form-search-right {
            margin-right: 0;
            right: 0;
            &:not(.collapsed) {
                width: 100% !important;
                right: 0 !important;
            }
        }
    }
    .navbar-expand-long {
        .form-search {
            &:not(.collapsed){
                width: calc(100% - $form-search-w - $form-search-w-m);
                right: $form-search-w;
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    .form-search {
        flex: 0 0 auto;
        width: 100%;
    }
    .input-search {
        .btn-link {
            display: none;
        }
    }
}

.form-group-account {
    width: $form-group-account-w;
    .form-select {
        border-radius: $input-search-border-radius;
        @extend .text-truncate;

    }
}

.multiSelectContainer {
    .searchWrapper {
        padding: 4px 8px 1px;
        min-height: $tooltip-msg-top;
        &:hover {
            box-shadow: $input-btn-focus-box-shadow;
            border-color: tint-color($primary, 50%);
        }
    }
    .highlightOption {
        background: none;
        color: $homepage-btn-color !important;
    }
    li:hover {
        background: $homepage-btn-bg-h !important;
        color: $homepage-btn-color !important;
    }
    .chip {
        background: $homepage-btn-border;
        color: $homepage-btn-color;
        border-radius: $tooltip-msg-border-radius;
        line-height: $font-size;
    }
    .searchBox {
        margin-top: 0;
        @extend .wp-80;
    }
    .icon_cancel {
        fill: $c-black;
        path {
            fill: $c-black;
        }
    }
}

.bi-line {
    background: $primary;
    @include sizes(1px,$font-size * $line-height);
    margin-bottom: 1px;
}

.form-group-search {
    position: relative;
    span {
        position: absolute;
        left: $input-padding-x;
        top: 50%;
        margin-top: - $font-size-sm * math.div($line-height-base,2);
        z-index: 1;
    }
    .form-control {
        padding-left: 3 * $input-padding-x;
    }
}


.select-search {
    position: relative;
    font-family: $font-family;
    box-sizing: border-box;
}
.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}
.select-search__value {
    position: relative;
    z-index: 1;
    $select-search-arrow-width1: math.div($select-search-arrow-width,2);
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - $select-search-arrow-width1 + 2px);
        right: $form-select-padding-x;
        @include sizes($select-search-arrow-width,$select-search-arrow-height);
        background-image: escape-svg($form-select-indicator);
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.select-search__input {
    display: block;
    outline: none;
    text-align: left;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    @extend .form-control;
    padding-right: 3*$input-padding-x;
    &:not([readonly]):focus {
        cursor: initial;
    }
}
.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
.select-search__select {
    background: $c-white;
    border: 1px solid $form-select-border-color;
}
.select-search__options {
    background: $c-white;
    list-style: none;
    margin: 0;
    padding-inline-start: 0;
}
.select-search__row:not(:first-child) {
    border-top: 1px solid $c-grey1;
}
.select-search__option,
.select-search__not-found {
    display: block;
    @include sizes(100%,$select-search-res-height);
    padding: 0 $input-padding-x;
    background: $c-white;
    border: none;
    outline: none;
    font-family: $font-family;
    font-size: $font-size;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}
.select-search__option {
    &.is-selected {
        background: $primary;
        color: $c-white;
    }
    &.is-highlighted,
    &:not(.is-selected):hover {
        background: $homepage-btn-bg-h;
    }
    &.is-highlighted.is-selected,
    &.is-selected:hover {
        background: $primary;
        color: $c-white;
    }
}
.select-search {
    &.is-disabled {
        opacity: 0.5;
    }
    &:not(.is-disabled) .select-search__input {
        cursor: pointer;
    }
    &:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: calc($select-search-res-height + 2 * $input-btn-padding-y);
        right: 0;
        left: 0;
        @include border-radius($input-border-radius, 0);
        overflow: auto;
        max-height: $select-search-max-height;
    }
}
.select-search__not-found {
    height: auto;
    padding: $input-btn-padding-y $input-btn-padding-x;
    text-align: center;
    color: $c-black1;
}
.form-select-role {
    div[class*="control"] {
        & {
            border-color: $input-border-color;
            box-shadow: none;
        }
        &:hover {
            box-shadow: $input-btn-focus-box-shadow;
            border-color: tint-color($primary, 50%);
        }
    }
    div[class*="option"]{
        background: $c-white;
        &:hover {
            background: $c-grey;
        }
        &:active {
            background: $input-btn-focus-color;
        }
    }
  div[class*="menu"]{
    z-index: 100000;
  }
}

.form-select-role.is-invalid {
    div[class*="control"] {
        & {
            border-color: $form-invalid-border-color;
        }
    }
}
/*search end*/

/*breadcrumbs start*/
.breadcrumbs {
    background-color: $primary;
    color: $c-white;
    font-size: $font-size;
    padding: $breadcrumbs-pad-y $breadcrumbs-pad-x;
    a {
        color: $c-white;
        &:after {
            content: "\f285";
            font-family: bootstrap-icons !important;
            line-height: 1;
            vertical-align: -0.125em;
            position: relative;
            display: inline-block;
            margin: $breadcrumbs-sep-marg-y $breadcrumbs-sep-marg-x;
        }
        &:hover {
        text-decoration: none;
    }
    }
    a, span {
        display: inline-block;
        padding: $breadcrumbs-marg-y $breadcrumbs-marg-x;
    }
}
/*breadcrumbs end*/

/*footer start*/
footer {
    padding: $footer-pad-y $footer-pad-x;
    background: $c-grey-dark;
    color: $footer-menu-txt;
    a {
        color: $footer-menu-link;
        &:hover {
            color: $footer-menu-link-hover;
        }
    }
    hr {
        background-color: $c-grey;
        margin-left: -$footer-pad-x;
        margin-right: -$footer-pad-x;
    }
    img {
        height: $footer-img-height;
    }
    ul {
        margin-bottom: 0;
        li {
            width: $footer-menu-link-width-lg;
            margin: math.div($spacer, 2) 0;
            a {
                text-decoration: none;
            }
        }
    }
}
@include media-breakpoint-down(lg) {
    footer {
        padding-top: 2*$footer-pad-y;
        hr {
            margin-top: 3*$spacer;
        }
        ul {
            li {
                width: $footer-menu-link-width;
            }
        }
    }
}
/*footer end*/

/*aside menu start*/
.bg-aside-collapse {
  z-index: 10;
  position: sticky;
  top: 0;
}
#aside-collapse {
  @include media-breakpoint-up(lg) {
    &.show {
      aside {
        width: $aside-width;
      }
    }
  }
}
.btn-add {
  border-radius: 50%;
  color: $primary;
  i {
    height: 24px;
    display: block;
    &::before {
      display: block;
    }
  }
}
.btn-aside {
  position: absolute;
  top: $header-pad-y;
  z-index: 1000;
  border-radius: 50%;
  background: $c-grey;
  border: 1px solid $secondary;
  width: $btn-aside-size;
  height: $btn-aside-size;
  padding: $btn-aside-pad-y $btn-aside-pad-x;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  @include media-breakpoint-up(lg) {
    left: calc($aside-width - $btn-aside-size - 1.5 * $btn-aside-pad-x);
  }
  @media (min-width: 992px) and (max-width: 1352.98px){
    top: $header-pad-y;
  }
  &:hover {
    background: $primary;
    color: $c-white;
    border-color: $primary;
  }
  i {
    display: block;
    width: $btn-aside-icon-size;
    height: $btn-aside-icon-size;
    margin-bottom: 0.5*$btn-aside-icon-size;
  }
  &:not(.collapsed) {
    i {
      &:before {
        margin-left: -1px;
      }
    }
  }
  &.collapsed {
    @include media-breakpoint-up(lg) {
      left: calc($content-pad-x - $aside-menu-pad-x);
    }
    i {
      &:before {
        content: "\f285";
      }
    }
  }
}

.bg-aside {
  @include media-breakpoint-up(lg) {
    background: $c-black1;
    min-width: $content-pad-x;
    max-width: $aside-width;
    min-height: calc(100vh - $header-height);
  }
}

aside {
    background: $aside-bg;
    z-index: 10;
    .navbar-toggler {
        right: $aside-menu-pad-x;
        top: 50%;
        margin-top: 0 - math.div($aside-menu-see-all-size,2);
        color: $aside-menu-c;
        padding: 0.4rem 0.7rem;
        border: none;
        z-index: 2;
        font-size: 0;
        height: $aside-menu-see-all-size;
        @include transition(background-color 0.3s 0s ease-in-out);
        &:hover {
            background-color: $aside-menu-c-active;
        }
    }
    header {
        display: block;
        position: relative;
        white-space: nowrap;
        background-color: $aside-header-bg;
        font-size: $font-size-sm;
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        padding: $aside-menu-pad-y $aside-menu-pad-x;
        color: $aside-header-c;
        border-bottom: 1px solid $aside-border-c;
        letter-spacing: 0.05em;
        text-rendering: optimizeLegibility;
    }
    ul {
        margin: 0;
        list-style-type: none;
        padding-left: 0;
        li {
            padding: $aside-menu-pad-y $aside-menu-pad-x;
            margin: 0;
            height: auto;
            border-bottom: 1px solid $aside-border-c;
            background-color: $aside-bg;
        }
        .main-sidebar-h {
          display: block;
          color: $aside-menu-c;
          font-size: $font-size;
          letter-spacing: 0.01em;
          font-weight: 700;
        }
        .main-sidebar-a {
            display: block;
            color: $aside-menu-c;
            font-size: $font-size;
            letter-spacing: 0.01em;
            font-weight: 700;
            text-decoration: none;
            @include transition(color 0.3s 0s ease-in-out);
            cursor: pointer;
            &.active {
              color: $aside-menu-c-active !important;
              &:hover {
                color: $aside-header-c !important;
              }
            }
        }
        button.main-sidebar-a {
          background: none !important;
          border: none !important;
          padding: 0;
        }
        .main-sidebar-toggle-nav {
          margin: $aside-menu-pad-y (0 - $aside-menu-pad-x) (0 - $aside-menu-pad-y);
          border-top: 1px solid $aside-border-c;
          li {
            padding: 0;
            .main-sidebar-a {
              padding: math.div($aside-menu-pad-y,2) $aside-menu-pad-x math.div($aside-menu-pad-y,2) 1.5*$aside-menu-pad-x;
            }
            &:last-child {
              border-bottom: none;
            }
          }
          .main-sidebar-a {
            font-size: 0.85*$font-size;
            color: $aside-header-c;
          }
        }
      .btn-toggle[aria-expanded="true"]::before {
        transform: rotate(90deg);
      }
      .btn-toggle::before {
        position: absolute;
        right: 0;
        top: 50%;
        display: inline-block;
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        vertical-align: -0.125em;
        -webkit-font-smoothing: antialiased;
        width: 1.25em;
        line-height: 0;
        content: "\f285";
        transition: transform .35s ease;
        transform-origin: 0.5em 50%;
      }
    }
    &[hidden]{
        & + .content-wrapper {
            width: 100%;
        }
    }
    @include media-breakpoint-up(lg) {
      width: $aside-width;
    }
}

@include media-breakpoint-down(lg) {
    .main-sidebar {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - $header-brand-height-md - 2*$header-pad-y - 2*$aside-menu-pad-y - $line-height*$font-size-sm - 2px);
    }
}
/*aside menu end*/

main {
  width: 100%;
  min-height: 100vh;
  position: relative;
  @extend .d-flex;
  @extend .flex-column;
  @extend .flex-lg-row;
  &.without-aside {
      .btn-aside,
      .bg-aside {
          display: none !important;
      }
      .content-wrapper {
        width: 100% !important;
        max-width: 100% !important;
        .notifications-container {
          @include media-breakpoint-up(xl) {
            max-width: 1140px;
            padding-left: 12px;
            padding-right: 12px;
            margin: 0 auto;
          }
          @include media-breakpoint-up(xxl) {
            max-width: 1320px;
          }
          @media (min-width: 1500px){
            max-width: 1460px;
          }
          @media (min-width: 1700px){
            max-width: 1660px;
          }
          @media (min-width: 1900px){
            max-width: 1860px;
          }
          @media (min-width: 2100px){
            max-width: 2060px;
          }
        }
        @include media-breakpoint-up(xl) {
          .content-notifications {
            max-width: 80%;
            margin: 0 auto;
          }
          .alert-cmp {
            &:first-child {
              margin-top: 25px;
            }
            max-width: 60%;
            margin: 0 auto;
          }
        }
      }
      .content {
          padding: 0;
      }
  }
  &.main-full {
    .content-wrapper {
      @include media-breakpoint-up(lg) {
        max-width: calc(100% - $content-pad-x);
      }
    }
  }
}

/*content start*/
.content-wrapper {
  padding: 0;
  @extend .flex-grow-1;
  min-height: 100vh;
  background: $c-white;
  @include media-breakpoint-up(lg) {
    max-width: calc(100% - $aside-width);
  }
  @media (min-width: 1500px){
    .container {
      max-width: 1460px;
    }
  }
  @media (min-width: 1700px){
    .container {
      max-width: 1660px;
    }
  }
  @media (min-width: 1900px){
    .container {
      max-width: 1860px;
    }
  }
  @media (min-width: 2100px){
    .container {
      max-width: 2060px;
    }
  }
}

@media (min-width: 1700px){
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .me-xxxl-3 {
    margin-right: 1rem !important;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

@include media-breakpoint-up(md) {
    .content {
        padding: $content-pad-y $content-pad-x;
    }
}
/*content end*/

/*sign in start*/
.form-signin {
    max-width: $signin-width;
    h1 {
        text-align: center;
    }
}
/*sign in end*/

/*styleguide start*/
.styleguide {
    section {
        padding-top: $styleguide-marg-y;
        margin-bottom: $styleguide-marg-y;
    }
}
.styleguide-head {
    margin-bottom: $styleguide-head-marg-b;
    h5 {
        font-weight: bold;
    }
}
.styleguide-code {
    margin-top: $styleguide-body-marg-t;
    background-color: $c-grey;
    padding: $styleguide-code-pad-y $styleguide-code-pad-x math.div($styleguide-code-pad-y, 2) $styleguide-code-pad-x;
}
.language-html {
    .tag {
        color: $c-blue;
    }
    .class {
        color: $c-purple;
    }
    .name {
        color: $c-green;
    }
}
/*styleguide end*/

/*homepage start*/
.homepage {
    .btn-homepage {
        @include size(100%);
        background-color: $homepage-btn-bg;
        border-color: $homepage-btn-border;
        color: $homepage-btn-color;
        padding: $homepage-btn-pad-y $homepage-btn-pad-x;
        &:hover {
            background-color: $homepage-btn-bg-h;
        }
        img {
            height: $homepage-btn-icon-size;
            max-width: 100%;
        }
    }
}
/*homepage end*/

/*registration start*/
.registration-block {
    @include media-breakpoint-up(lg) {
        max-width: 80%;
        margin: 0 auto;
    }
}
/*registration end*/

/*login start*/
.login-block {
    .btn-primary.btn-link {
        background: none;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0;
        &:focus,
        &:hover {
            color: $primary;
            text-decoration: none;
        }
    }
    @include media-breakpoint-up(lg) {
        max-width: 60%;
        margin: 0 auto;
    }
}
/*login end*/

.accordion-collapse {
    .btn.nav-link {
        line-height: 1.25;
        color: var(--bs-btn-active-bg);
        background-color: transparent;
        border: 1px solid var(--bs-btn-active-border-color);
        &.active {
            color: var(--bs-btn-active-color);
            background-color: var(--bs-btn-active-bg);
        }
    }
    .flex-column {
        .nav-item {
            &:not(:last-child) .btn {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:not(:first-child) .btn {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
            &:not(:first-child) .btn,
            &:not(:first-child) .btn {
                margin-top: -1px;
            }
        }
    }
}

/*table start*/
.table-collapse-a {
    &:not(.collapsed) {
        i {
            &::before {
                content: "\f229";
            }
        }
    }
}
.table-list {
    @extend .list-unstyled;
    @extend .mb-0;
    li + li {
        @extend .mt-2;
    }
}
.table {
    thead {
        th {
            background-color: $c-grey;
        }
    }
    tbody {
        td {
            vertical-align: middle;
            .btn-link {
                text-decoration: none;
                &:hover {
                    color: $secondary;
                }
            }
            &:not(.no-icons){
                .badge {
                    width: 100%;
                    padding-left: $table-badge-pad-s;
                    i {
                        position: absolute;
                        left: $table-badge-i-l;
                        top: $table-badge-i-t;
                    }
                }
            }
            span.disabled {
              opacity: 0.5;
            }
        }
    }
  .react-datepicker-popper {
    position: fixed !important;
  }
  .tooltip-wr {
    .tooltip-msg {
      margin-left: -10px !important;
      white-space: normal;
      &:before {
        left: 13px;
      }
      &:after {
        left: 12px;
        top: -13px;
      }
    }
  }
}

.edit-user-license {
  .form-select-role div[class*=control] {
    & + div {
      bottom: 100% !important;
      top: auto !important;
    }
  }
}

.input-calendar {
  position: relative;
  i {
    position: absolute;
    right: 12px;
    bottom: 6px;
  }
}

.modal-body {
  .table {
    tbody {
      td {
        &.no-icons {
          .max-w-150,
          .max-wper-90 {
            width: auto;
            max-width: none !important;
            display: inline-flex !important;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

table.non-selectable {
    th,td {
        user-select: none;
    }
}
.table-fix-height {
  .table-responsive {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}
.row-green {
  td, th {
    background: lighten($success,55%) !important;
  }
}
.table-hover > tbody > tr.row-green:hover > * {
  --bs-table-accent-bg: lighten($success,55%) !important;
}

table {
  tr.selected-row {
    td {
      background-color: $c-green;
    }
  }
}
/*table end*/

/*modal start*/
.modal {
    &:not(.show){
        opacity: 0;
    }
    transition: opacity 0.5s ease;
}
.modal-dialog {
    @extend .modal-dialog-centered;
    #multiselectContainerReact .optionListContainer {
        &.displayBlock {
            bottom: 100%;
        }
    }
}
$max-h: calc(100vh - $modal-dialog-margin-y-sm-up * 2 - 2 * $modal-header-padding-y - $h5-font-size * $modal-title-line-height - 2 * $modal-inner-padding + $modal-footer-margin-between - 2 * $btn-padding-y - $modal-footer-margin-between - 1rem * $modal-title-line-height );
.modal-wr-lg {
    .modal-dialog {
        @extend .modal-lg;
        @extend .modal-dialog-scrollable;
        .modal-body {
            max-height: $max-h;
        }
    }
}
.modal-wr-xl {
    .modal-dialog {
        @extend .modal-xl;
        @extend .modal-dialog-scrollable;
        .modal-body {
            max-height: $max-h;
        }
    }
}
.modal-wr-fullscreen {
    .modal-dialog {
        @extend .modal-fullscreen;
    }
    .modal-content {
        @extend .overflow-auto;
    }
}
.modal-header {
    background: $c-grey;
    padding-top: $modal-header-padding-y;
    padding-bottom: $modal-header-padding-y;
    h5 {
        font-weight: bold;
        min-height: $h5-font-size * $modal-title-line-height;
    }
}
.modal-footer {
    padding-top: calc($modal-header-padding-y - $modal-footer-margin-between * .25);
    padding-bottom: calc($modal-header-padding-y - $modal-footer-margin-between * .25);
    .btn {
        transition: none !important;
    }
}
/*modal end*/

/*tooltip start*/
.popover {
    box-shadow: $popover-box-shadow;
}
.tooltip-icon {
    color: $primary;
    cursor: pointer;
}
.tooltip-msg {
    @include transition(color 0.3s ease);
    display: none;
    position: absolute;
    left: 50%;
    top: $tooltip-msg-top;
    width: $tooltip-msg-width;
    margin-left: - math.div($tooltip-msg-width,2) - 5px;
    padding: $tooltip-msg-pad-y $tooltip-msg-pad-x 0 $tooltip-msg-pad-x;
    z-index: $tooltip-msg-z-index;
    background: $c-grey;
    border-radius: $tooltip-msg-border-radius;
    border: 1px solid $tooltip-msg-border-color;
    &:before,
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        @include size(0);
        border-style: solid;
    }
    &:before {
        top: 0 - $tooltip-msg-arrow-width - 2px;
        margin-left: - math.div($tooltip-msg-arrow-width,2) - 2px;
        border-width: 0 ($tooltip-msg-arrow-height + 2px) ($tooltip-msg-arrow-width + 2px) ($tooltip-msg-arrow-height + 2px);
        border-color: transparent transparent $c-grey1 transparent;
    }
    &:after {
        top: 0 - $tooltip-msg-arrow-width;
        margin-left: - math.div($tooltip-msg-arrow-width,2);
        border-width: 0 $tooltip-msg-arrow-height $tooltip-msg-arrow-width $tooltip-msg-arrow-height;
        border-color: transparent transparent $c-grey transparent;
    }
    @include media-breakpoint-down(xl){
        left: auto;
        right: - $tooltip-msg-arrow-width;
        margin-left: 0;
        &:before {
            left: auto;
            right: math.div($tooltip-msg-arrow-width,2) - 1px;
        }
        &:after {
            left: auto;
            right: math.div($tooltip-msg-arrow-width,2) + 1px;
        }
    }
    @include media-breakpoint-down(md){
        max-width: $tooltip-msg-width-md;
        top: 2 * math.div($tooltip-msg-top,3);
        left: auto;
        right: 0;
        width: calc(100vw - 2.5rem);
        &:before,
        &:after {
            display: none;
        }
    }
    @include media-breakpoint-down(sm){
        max-width: $tooltip-msg-width-sm;
    }
}
.tooltip-wr:hover {
    .tooltip-msg {
        display: block;
    }
}
.tooltip-wr-small {
    .tooltip-msg {
      left: 0;
      width: math.div($tooltip-msg-width,1.95);
      margin-left: - math.div($tooltip-msg-width,4) + 3px;
        @include media-breakpoint-down(xl){
            &:before,
            &:after {
                left: 50%;
                right: auto;
                margin-left: 0;
            }
        }
    }
    @include media-breakpoint-down(sm){
      position: initial;
      .tooltip-msg {
        margin-left: 0;
      }
    }
}

.table {
  .tooltip-wr-certify {
    .tooltip-msg {
      top: -47px;
      width: 770px;
      padding: 9px 9px 0 10px;
      margin-left: -($tooltip-msg-arrow-width + $tooltip-msg-arrow-height) !important;
      p {
        margin-bottom: 0.5rem;
      }
      &:before {
        bottom: -14px;
        left: 20px;
        top: auto;
        border-style: solid;
        border-width: ($tooltip-msg-arrow-height + 2px) ($tooltip-msg-arrow-width + 2px) 0 ($tooltip-msg-arrow-height + 2px);
        border-color: $c-grey1 transparent transparent transparent;
      }
      &:after {
        bottom: -12px;
        left: 20px;
        top: auto !important;
        border-style: solid;
        border-width: $tooltip-msg-arrow-height $tooltip-msg-arrow-width 0 $tooltip-msg-arrow-height;
        border-color: $c-grey transparent transparent  transparent;
      }
      @include media-breakpoint-down(xxl){
        top: -40px;
        left: auto;
        right: auto;
        margin-left: -5px !important;
        &:before,
        &:after {
          display: none;
        }
      }
      @include media-breakpoint-down(xl){
        font-size: $font-size-sm;
        width: 365px;
        top: -58px;
      }
      @media (max-width: 460px) {
        width: 260px;
        top: -80px;
      }
    }
  }
}

.tooltip-wr-table {
  float: left;
  margin-top: 1px;
  .tooltip-msg {
    top: -49px;
    left: 0;
    width: $tooltip-msg-table;
    margin-left: -18px !important;
    padding: 9px 9px 0 10px;
    p {
      margin-bottom: 0.5rem;
    }
    &:before {
      bottom: -14px;
      left: 20px;
      top: auto;
      border-style: solid;
      border-width: ($tooltip-msg-arrow-height + 2px) ($tooltip-msg-arrow-width + 2px) 0 ($tooltip-msg-arrow-height + 2px);
      border-color: $c-grey1 transparent transparent transparent;
    }
    &:after {
      bottom: -12px;
      left: 20px;
      top: auto !important;
      border-style: solid;
      border-width: $tooltip-msg-arrow-height $tooltip-msg-arrow-width 0 $tooltip-msg-arrow-height;
      border-color: $c-grey transparent transparent  transparent;
    }
    @include media-breakpoint-down(xxl){
      top: -40px;
      right: 0;
      left: auto;
      &:before,
      &:after {
       display: none;
      }
    }
  }
  @include media-breakpoint-down(sm){
    position: initial;
    .tooltip-msg {
      margin-right: 0;
    }
  }
}

.license-select-link {
  @extend .btn;
  @extend .btn-link;
  @extend .ps-0;
  @extend .pe-2;
  @extend .py-0;
  &:hover {
    text-decoration: none !important;
  }
}

.tooltip-wr-discount {
  .tooltip-msg {
    padding-top: 15px;
    left: 0;
    width: math.div($tooltip-msg-width-disc,1.95);
    margin-left: - math.div($tooltip-msg-width-disc,4) + 3px;
    top: auto;
    bottom: $tooltip-msg-top;
    &:before {
      top: auto;
      bottom: 0 - $tooltip-msg-arrow-width;
      border-width: ($tooltip-msg-arrow-height + 2px) ($tooltip-msg-arrow-width + 2px) 0 ($tooltip-msg-arrow-height + 2px);
      border-color: $c-grey1 transparent transparent transparent;
    }
    &:after {
      top: auto;
      bottom: 0 - $tooltip-msg-arrow-width + 2px;
      border-width: $tooltip-msg-arrow-height $tooltip-msg-arrow-width 0 $tooltip-msg-arrow-height;
      border-color:  $c-grey transparent transparent transparent;
    }
    @include media-breakpoint-down(md){
      bottom: 2 * math.div($tooltip-msg-top,3);
    }
    @include media-breakpoint-down(xl){
      &:before,
      &:after {
        left: 45%;
        right: auto;
        margin-left: 0;
      }
      &:before {
        left: calc(45%  - 2px);
      }
    }
  }
  @include media-breakpoint-down(sm){
    position: initial;
    .tooltip-msg {
      margin-left: 0;
    }
  }
}

@media (min-width: 1550px) {
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 25%;
  }
}

@include media-breakpoint-up(xl) {
  .listing-search-head {
    p {
      max-width: 93%;
    }
  }
}

.toast {
    .toast-header {
        padding-top: 0.8 * $toast-padding-x;
    }
    .toast-body {
        padding-top: 0;
        padding-bottom: 0.8 * $toast-padding-x;
        background-color: $toast-body-bg;
        @include border-bottom-radius(subtract($toast-border-radius, $toast-border-width));
    }
    .btn-close {
      @extend .btn-close-white;
        margin-right: math.div(-$toast-padding-x,3);
    }
}
/*tooltip end*/

/*print start*/
@media print {
    .print {
        .modal-header {
            border-bottom-width: 2px;
            border-bottom-color: $secondary;
        }
        table {
            th, td {
                padding-left: 0;
            }
        }
    }
}
/*print end*/

.price-posting-cnt {
  position: relative;
  .switch-year {
    position: absolute;
    right: 0;
    top: -1px;
  }
  .table-responsive {
    & + h5 {
      margin-top: 2rem;
    }
    .bi-percent {
      position: absolute;
      left: 7px;
      top: 4px;
      font-size: 10px;
    }
  }
}

.tooltip-icon-percent {
  .bi-percent {
    position: absolute;
    left: 3px;
    top: 3px;
    font-size: 10px;
    @include media-breakpoint-down(lg) {
      left: 2px;
      top: 2px;
    }
  }
}

.arrow-right-big {
  position: absolute;
  left: 0;
  margin-left: calc(-0.75rem - 1.25*$font-size);
  margin-top: -0.75rem;
  top: 50%;
  font-size: 2.5*$font-size;
  color: $primary;
  @include media-breakpoint-down(lg) {
    left: 50%;
    top: -3rem;
    width: 2.5*$font-size;
    margin-left: -1.25*$font-size;
    &.bi-arrow-right-circle::before {
      content: "\f119" !important;
    }
  }
}

.bi-person-gear::before {
  content: "\f8a7";
}

.bi-clock.fs-5 {
  margin-top: 1px;
}

.product-item-remove {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 30;
  @include media-breakpoint-down(lg) {
    & + .btn-group.nav.nav-pills {
      padding-right: 40px;
    }
  }
}

.product-item-head {
  text-decoration: none !important;
  .bi-chevron-down {
    font-weight: bold;
  }
  &.collapsed {
    .bi-chevron-down::before {
      content: "\f285" !important;
    }
  }
  & + .collapse {
    hr {
      margin-bottom: 0;
    }
  }
}

.jobs-success {
  min-height: 255px;
}
.jobs-success {
  .card {
    background-color: $success-bg;
    border-color: darken($success-bg,5%);
  }
  .badge {
    @extend .bg-success;
  }
}
.jobs-grey {
  .badge {
    @extend .bg-info;
    i {
      &:before {
        content: "\f292";
      }
    }
  }
}
.jobs-danger {
  .card {
    background-color: $danger-bg;
    border-color: darken($danger-bg,5%);
  }
  .badge {
    @extend .bg-danger;
    i {
      &:before {
        content: "\f333";
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .add-license {
    .wp-250,
    .flex-fill,
    .btn {
      width: 100%;
      max-width: 100%;
    }
  }
}

.batch-link {
  @extend .btn;
  @extend .btn-link;
  @extend .px-0;
  &:hover {
    text-decoration: none;
  }
  &:not(.collapsed) {
    i {
      &::before {
        content: "\f286";
      }
    }
  }
}

.link-copy {
  color: $primary;
  position: absolute;
  right: 9px;
  top: 8px;
  &:hover {
    color: $secondary;
  }
}

.input-with-copy {
  padding-right: 33px !important;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 1500px){
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

.ml-1 {
  margin-left: 1rem;
}

.mb-0-5 {
  margin-bottom: 0.5rem;
}
