@use           "sass:math";
$base-url:     '';
$font-family:  'Nunito Sans', sans-serif;;
$font-size:    16px;
$font-size-sm: 14px;
$line-height:  1.5;

$c-white:      #ffffff;
$c-black:      #000000;
$c-black1:     #242424;
$c-blue:       #0f739d;
$c-purple:     #6e32e5;
$c-teal:       #0b5d66;
$c-green:      #28b012;
$c-darkgreen:  #208011;
$c-teal-light: #e5eeee;
$c-grey:       #f8f8f8;
$c-grey1:      #e4e4e4;
$c-grey-dark:  #333333;
$c-yellow:     #FACE00;
$c-orange:     #e39915;
$c-grape:      #de630b;
$c-pink:       #ea468a;
$c-beige:      #b39b64;
$primary:      $c-teal !default;
$secondary:    $c-grey-dark !default;
$success:      $c-green;
$danger-bg:    #f8d7da;
$success-bg:   #d5ead1;

$h1-font-size:               $font-size * 2.25;
$h1-font-weight:             bold;

$navbar-toggler-padding-y:   .55rem;
$navbar-toggler-padding-x:   1.5rem;
$navbar-toggler-icon-width:  $font-size;
$header-nav-font-size:       20px;
$header-nav-link-color:      $c-black;
$header-nav-link-color-h:    $c-blue;
$header-nav-separator-color: #d9e2e2;
$header-brand-height:        66px;
$header-brand-height-md:     calc(0.8*$header-brand-height);

$breadcrumbs-pad-y:          15px;
$breadcrumbs-pad-x:          1rem;
$breadcrumbs-marg-y:         5px;
$breadcrumbs-marg-x:         0;
$breadcrumbs-sep-marg-y:     0;
$breadcrumbs-sep-marg-x:     10px;

$input-search-transition:    width 0.35s ease;
$input-search-marg-e:        50px;
$input-search-width:         150px;
$input-search-border-radius: 30px;
$input-search-c-link:        #128ec3;

$btn-hover-bg-shade-amount:  20%;
$btn-aside-size:             36px;
$btn-aside-pad-x:            10px;
$btn-aside-pad-y:            6px;
$btn-aside-top:              14px;
$btn-aside-icon-size:        14px;

$header-pad-y:               math.div(3rem, 4);
$header-pad-x:               1rem;
$header-height:              91px;
$header-height-md:           79px;

$footer-pad-y:               1rem;
$footer-pad-x:               1rem;
$footer-img-height:          55px;
$footer-menu-txt:            $c-white;
$footer-menu-link-width:     50%;
$footer-menu-link-width-lg:  20%;
$footer-menu-link:           $c-white;
$footer-menu-link-hover:     $c-yellow;

$signin-width:               500px;

$card-bg:                    $c-grey;
$card-border-color:          $c-grey1;
$card-spacer-y:              1.5rem;
$card-spacer-x:              2rem;

$form-label-font-weight:     bold;
$form-label-margin-bottom:   .3rem;
$form-group-marg-b:          1.2rem;

$input-or-marg-t-lg:         -0.3rem;
$input-or-marg-t:            -1rem;
$input-or-marg-left:         -0.75rem;

$aside-bg:                   $c-black1;
$aside-header-bg:            #1d1d1d;
$aside-header-c:             #cbcbcb;
$aside-border-c:             #404040;
$aside-width:                280px;
$aside-menu-pad-y:           21px;
$aside-menu-pad-x:           22px;
$aside-menu-c:               $c-white;
$aside-menu-c-active:        $c-orange;
$aside-menu-triangle-size:   20px;
$aside-menu-see-all-size:    28px;

$content-pad-y:              0;
$content-pad-x:              35px;

$styleguide-marg-y:          1.5rem;
$styleguide-head-marg-b:     2rem;
$styleguide-body-marg-t:     2.5rem;
$styleguide-code-pad-y:      1.5rem;
$styleguide-code-pad-x:      2rem;

$homepage-btn-pad-x:         1rem;
$homepage-btn-pad-y:         1.5rem;
$homepage-btn-bg:            $c-grey;
$homepage-btn-bg-h:          darken($homepage-btn-bg,2.5%);
$homepage-btn-border:        $c-grey1;
$homepage-btn-color:         $c-black1;
$homepage-btn-icon-size:     50px;
$homepage-btn-icon-color:    $primary;

$alert-padding-sm-y:         0.375rem;
$alert-padding-sm-x:         0.75rem;

$invalid-message-font-size:  0.875em;
$invalid-message-pad-x:      1rem;

$pagination-color:           $secondary;
$pagination-bg:              $c-grey;
$pagination-border-color:    $c-grey1;

$select-search-arrow-width:  16px;
$select-search-arrow-height: 12px;
$select-search-res-height:   36px;
$select-search-max-height:   290px;

$search-app-letter-width:    775px;

$table-hover-bg:             $c-grey;
$table-badge-pad-s:          25px;
$table-badge-i-t:            4px;
$table-badge-i-l:            6px;

$modal-inner-padding:        1.8rem;
$modal-header-padding-y:     1.15rem;
$modal-footer-margin-between:1rem;

$tooltip-msg-z-index:        1;
$tooltip-msg-width:          640px;
$tooltip-msg-table:          144px;
$tooltip-msg-width-md:       515px;
$tooltip-msg-width-sm:       315px;
$tooltip-msg-width-disc:     420px;
$tooltip-msg-top:            38px;
$tooltip-msg-pad-y:          20px;
$tooltip-msg-pad-x:          20px;
$tooltip-msg-border-radius:  0.3rem;
$tooltip-msg-border-color:   $c-grey1;
$tooltip-msg-arrow-width:    14px;
$tooltip-msg-arrow-height:   12px;

$caret-vertical-align:       0.155em;
$caret-spacing:              0.4em;

$steps-li-h:                 64px;

$form-group-account-w:       300px;
$form-group-account-w-lg:    180px;

$form-search-w:              352px;
$form-search-w-lg:           232px;
$form-search-w-m:            36px;

$toast-color:                $c-white;
$toast-font-size:            1rem;
$toast-body-bg:              rgba($c-white, 0);
$toast-header-color:         $toast-color;
$toast-header-background-color: $toast-body-bg;
$toast-header-border-color:  $toast-body-bg;
$toast-border-radius:        $tooltip-msg-border-radius;
$toast-padding-x:            1.2rem;

$popover-header-bg:          $c-grey;
$popover-border-color:       darken($c-grey1,3%);
$popover-box-shadow:         rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$popover-body-padding-y:     .5rem;
